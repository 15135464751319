import * as React from "react";
import {Container, Col, NavLink, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faLocation, faLocationDot, faLocationPin, faPhone} from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faFacebookSquare, faInstagram, faInstagramSquare, faTwitter, faTwitterSquare } from "@fortawesome/free-brands-svg-icons";

const FooterContactDetails = () => {
    return (
        <Row className="gy-4 p-5">
            {/* <Col xs={3} className="d-flex flex-row justify-content-start align-items-center">
                <FontAwesomeIcon className="me-2 text-primary" icon={faLocationPin}/>
            </Col>
            <Col xs={9}>   
                <p className="mb-0">Colton, Leeds</p>            
            </Col> */}
            <Col xs={12}>
                <h1 className="mb-0">Ready to start your Project?</h1>
            </Col>
            <Col xs={12}>
                <p>From extensions to summer houses, here at BDM Building Projects no project is too small or too big. Contact us below for a free consultation.</p>
            </Col>
            <Col xs={12} className="d-flex flex-row justify-content-start align-items-center">
                <Col className="d-flex flex-row justify-content-start align-items-center">
                    <FontAwesomeIcon className="me-2 text-primary" icon={faPhone}/>
                    <p className="mb-0">07850349200</p>
                </Col>
                <Col className="d-flex flex-row justify-content-start align-items-center">
                    <FontAwesomeIcon className="me-2 text-primary" icon={faEnvelope}/>
                    {/*TODO make "Email Us" gold on hover*/}
                    <NavLink className="p-0 text-dark" href="mailto: bdmbuildingprojects@gmail.com">Email Us</NavLink>
                </Col>
            </Col>
            {/* <Col xs={9}>   
                <p className="mb-0">+44 7951 482533</p>            
            </Col>
            <Col xs={3} className="d-flex flex-row justify-content-start align-items-center">
                <FontAwesomeIcon className="me-2 text-primary" icon={faEnvelope}/>
            </Col>
            <Col xs={9}>
                <p className="mb-0">bdmbuildingprojects@gmail.com</p>
            </Col> */}
            {/* <Col xs={12}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4713.712324199051!2d-1.4420657225248932!3d53.792047558022965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487943344e22d23f%3A0xafd078e45a177853!2sColton%2C%20Leeds!5e0!3m2!1sen!2suk!4v1657789944149!5m2!1sen!2suk" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </Col> */}
            <Col xs={12}>
                <hr className="text-primary m-0"/>
            </Col>
            <Col xs={12} className="d-flex flex-row justify-content-start align-items-center text-primary">
                    <Row className="d-flex flex-row justify-content-center align-items-center gx-2 py-1">
                        <Col className="d-flex flex-row justify-content-center align-items-center">
                            <FontAwesomeIcon className="fa-xl" icon={faFacebookSquare}/>
                        </Col>
                        <Col className="d-flex flex-row justify-content-center align-items-center">
                            <FontAwesomeIcon className="fa-xl" icon={faInstagramSquare}/>                    
                        </Col>
                        <Col className="d-flex flex-row justify-content-center align-items-center">
                            <FontAwesomeIcon className="fa-xl" icon={faTwitterSquare}/>
                        </Col>
                    </Row>
                </Col>
        </Row>
    );
}

export default FooterContactDetails;