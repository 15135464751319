import * as React from "react";
import {Row, Col} from "react-bootstrap";

const FooterTitle = () => {
    return (
        <Row className="gy-3">
            <Col xs={12}>
                <h1 className="text-center mb-0">Ready to start your Project?</h1>
            </Col>
            <Col xs={12}>
                <hr className="text-primary m-0"/>
            </Col>
            <Col xs={12}>
                <Row className="d-flex flex-row justify-content-center">
                    <Col className="text-center" xs={8}>
                        <p>From extensions to summer houses, here at BDM Building Projects no project is too small or too big. Contact us below for a free consultation.</p>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default FooterTitle;