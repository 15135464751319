import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";
import FooterContactDetails from "./footerContactDetails";
import FooterForm from "./footerForm";
import FooterTitle from "./footerTitle";
import { faFacebook, faFacebookSquare, faInstagram, faInstagramSquare, faTwitter, faTwitterSquare } from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const FooterSocial = () => {
    return (
        <Row className="gy-3">
            <Col xs={12}>
                <hr className="text-primary m-0"/>
            </Col>
            <Col xs={12} className="d-flex flex-row justify-content-center align-items-center text-primary">
                <Row className="d-flex flex-row justify-content-center align-items-center gx-2 py-1">
                    <Col className="d-flex flex-row justify-content-center align-items-center">
                        <FontAwesomeIcon className="fa-xl" icon={faFacebookSquare}/>
                    </Col>
                    <Col className="d-flex flex-row justify-content-center align-items-center">
                        <FontAwesomeIcon className="fa-xl" icon={faInstagramSquare}/>                    
                    </Col>
                    <Col className="d-flex flex-row justify-content-center align-items-center">
                        <FontAwesomeIcon className="fa-xl" icon={faTwitterSquare}/>
                    </Col>
                </Row>
            </Col>        
        </Row>

    );
}

export default FooterSocial;